import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    testing: true,
    auth: {
      isLogin: false,
      token: ""
    },
    login:{
      username: "",
      password: "",
      isLoading: false
    },
    scan:{
      value: "",
      alreadyCheck: false,
      isFound: false,
      isReadyToMapping: false,
      serialNumber: ""
    },
    detailMapping:{
      serialNumber: "",
      tanggalMapping: "",
      region: "",
      provinsi: "",
      kota : "",
    },
  },
  getters: {
  },
  mutations: {
    updateStatusLoading(state, payload){
      state.login.isLoading = payload;
    },
    updateStatusLogin(state, payload){
      state.auth.isLogin = payload
    },
    updateAuth(state, payload){
      state.auth.isLogin = payload.isLogin,
      state.auth.token = payload.token
    },
    updateHasilScan(state, payload){
      state.scan.value = payload.value
      state.scan.isFound = payload.isFound
      state.scan.isReadyToMapping = payload.isReadyToMapping
      state.scan.alreadyCheck = payload.alreadyCheck
      state.scan.serialNumber = payload.serialNumber
    },
    updateDetailMapping(state, payload){
      state.detailMapping = payload
    }

  },
  actions: {
    updateStatusLoading({ commit }, payload) {
      commit('updateStatusLoading', payload);
    },
    updateStatusLogin({commit}, payload){
      commit('updateStatusLogin', payload)
    },
    updateHasilScan({commit}, payload){
      commit('updateHasilScan', payload)
    },
    updateAuth({commit}, payload){
      commit('updateAuth', payload)
    },
    updateDetailMapping({commit}, payload){
      commit('updateDetailMapping', payload)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage, // or window.sessionStorage
    }),
  ],
})
