import { createRouter, createWebHistory } from 'vue-router'
import LoginView from "./../views/auth/LoginView.vue"
import ScanView from "./../views/scan/ScanView.vue"
import ScanSuccess from "./../views/scan/ScanSuccessView.vue"
import LogoutView from "./../views/auth/LogoutView.vue"
import ScanNotFound from "./../views/scan/ScanNotFoundView.vue"
import ScanAlreadyMappedView from "./../views/scan/ScanAlreadyMappedView.vue"
import TestTorchView from "./../views/dev/TestTorchView.vue"
import ScanRegisteredOtherMerchantView from "./../views/scan/ScanRegisteredOtherMerchantView.vue"

import store from "./../store"
const routes = [
  {
    path: '/',
    name: 'Scan',
    component: ScanView,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
   // meta: { requiresLogin: true }
  },
  {
    path: '/success',
    name: 'Success',
    component: ScanSuccess,
   // meta: { requiresLogin: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutView,
    meta: { requiresLogin: false }
  },
  {
    path: '/notfound',
    name: 'Not Found',
    component: ScanNotFound,
  },
  {
    path: '/torch',
    name: 'Testing Torch',
    component: TestTorchView,
  },
  {
    path: '/already_mapped',
    name: 'Alredy Mapped',
    component: ScanAlreadyMappedView,
  },
  {
    path: '/registered',
    name: 'Alredy Registered To Other Mechant',
    component: ScanRegisteredOtherMerchantView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.name;
  console.log(store.state.auth.isLogin);
  if(to.matched.some(record => record.name == "Login" && store.state.auth.isLogin == true)){
    next("/");
  }else{
  if ((to.matched.some(record => record.meta.requiresLogin)) && store.state.auth.isLogin == false) {
    next("/login")
  } else {
    next()
  }
  next();
  }
});

export default router
