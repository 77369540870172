<template lang="">
    <a-row type="flex" justify="center">
    <a-col :span="24" :lg="5" class="container">
        <div style="height:20vh;">.</div>
        <br>
        <qrcode-stream @detect="onDetect"></qrcode-stream>
       
    </a-col>
    <a-col :span="24" :lg="5" class="kotak_frame">
        <div class="kotak_atas">
            
            <img src="@/assets/flash.svg" alt="" class="flash" :class="{ 'flash_on': torch }" @click="toggleTorch">
           
            <p class="title">Position the qrcode in the area</p>
            <p class="sub_title">Scanning will start automatically</p>
        </div>
        <div class="kotak_camera">
            <div class="camera_kiri"></div>
            <div class="camera_tengah"></div>
            <div class="camera_kanan"></div>

        </div>
        <div class="kotak_bawah">
        </div>

    </a-col>
</a-row>
</template>
<script>
import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons-vue';
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import { mapActions, mapState } from 'vuex';
import axios from './../../axios/axios';
import moment from 'moment';
export default {
    components(){
        StarOutlined
    },
    data() {
        return {
            hasil: "kosong",
            torch: false
        }
    },
    components: {
        QrcodeStream,
        QrcodeDropZone,
        QrcodeCapture
    },
    created(){
        //alert(this.torch)
    },
    methods: {
        async toggleFlashlight() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                const track = stream.getVideoTracks()[0];
                await track.applyConstraints({ advanced: [{ torch: !track.getCapabilities().torch.mode }] });
                stream.getTracks().forEach((t) => t.stop());
            } catch (error) {
                console.error('Failed to access the flashlight:', error);
            }
        },
        async toggleTorch(){
            if(this.torch == true){
                this.turnOffFlashlight();
            }else{
                this.turnOnFlashlight()
            }
        },
        async turnOnFlashlight() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                const track = stream.getVideoTracks()[0];
                await track.applyConstraints({ advanced: [{ torch: true }] });
                stream.getTracks().forEach((t) => t.stop());
                this.torch = true;
            } catch (error) {
                //alert('Failed to turn on the flashlight: '+error);
                try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { torch: true } });
                // Access granted - handle flashlight control here
                // For instance, to turn on the flashlight:
                const track = stream.getVideoTracks()[0];
                await track.applyConstraints({ advanced: [{ torch: true }] });
                } catch (error) {
                // Access denied or error occurred
                    console.error('Error - Could not access camera:', error);
                    alert('Error - Failed to turn on the flashlight :: '+error);
                }
                console.error('Failed to turn on the flashlight:', error);
            }
        },

        // Function to turn off the flashlight
        async turnOffFlashlight() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                const track = stream.getVideoTracks()[0];
                await track.applyConstraints({ advanced: [{ torch: false }] });
                stream.getTracks().forEach((t) => t.stop());
                this.torch = false
            } catch (error) {
                alert('Failed to turn on the flashlight: '+error);

                console.error('Failed to turn off the flashlight:', error);
            }
        },
        async onDetect(detectedCodes) {
            try {
                console.log(detectedCodes);
                this.hasil = detectedCodes[0].rawValue
                this.checkQrCode()
                
            } catch (error) {
                console.log(error);
            }
        },
        checkQrCode(){
            let type = 'package'
            if (this.hasil.includes('HTTP')) {
                type = 'qrcode'
            }
            axios.get(`/api/check-scan?type=${type}&number=${this.hasil}`)
                .then((response) => {
                    console.log(response.data);
                    if(response.data.code == 50){
                        // already mapping
                        let scanResult = {
                            value: this.hasil,
                            isFound: true,
                            isReadyToMapping: false,
                            alreadyCheck : false,
                            serialNumber: response.data.data.serial_number
                        }
                        this.updateHasilScan(scanResult)

                        let data = response.data.data;
                        let tanggal = moment(data.mapping_date).format('DD MMMM YYYY | HH:mm');
                        let dataMapping = {
                            serialNumber: (data.serial_number != "")?data.serial_number: data.number,
                            tanggalMapping: tanggal,
                            region: data.region,
                            provinsi: data.province,
                            kota: data.city,
                        
                        }
                      
                        this.updateDetailMapping(dataMapping)
                        this.$router.push({ path: "/already_mapped" });

                        /* if (this.auth.isLogin == true) {
                            this.$router.push({ path: "/already_mapped" });
                        } */
                    }else{
                        // ready to mapping
                        let scanResult = {
                            value: this.hasil,
                            isFound: true,
                            isReadyToMapping: true,
                            alreadyCheck: true,
                            serialNumber: (response.data.data.serial_number != "")? response.data.data.serial_number:response.data.data.package
                        }
                        this.updateHasilScan(scanResult)
                        
                    }
                })
                .catch((error)=>{
                    console.log(error.response.status);
                    if(error.response.status == 404){
                        this.$router.push({ path: "/notfound", replace: true });
                    }
                    if(error.response.status == 401){
                        console.log('redirect');
                       /*  let scanResult = {
                            value: this.hasil,
                            isFound: true,
                            isReadyToMapping: false,
                            alreadyCheck: false
                        }
                        console.log('scan result');
                        console.log(scanResult);
                        this.updateHasilScan(scanResult) */
                        let data = {
                            isLogin: false,
                            token: ""
                        }
                        this.updateAuth(data);
                        this.$router.push({ path: "/login", replace: true });
                        
                    }
                })
        },
        logout() {

            let data = {
                isLogin: false,
                token: ""
            }
            this.updateAuth(data);
            this.$router.push({ path: "/logout", replace: true })
        },
        ...mapActions(["updateHasilScan", "updateAuth", "updateDetailMapping"])
    },
    computed: {
        ...mapState(['auth'])
    }
}
</script>
<style scoped>
.kotak_frame {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 1000;
    top: 0;
    bottom: 0;
}

.kotak_atas {
    height: 25%;
    width: 100%;
    background-color: #5c5c5c;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;

}

.kotak_camera {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.camera_kiri {
    width: 10%;
    background-color: #5c5c5c;
}

.camera_kanan {
    width: 10%;
    background-color: #5c5c5c;
}

.camera_tengah {
    width: 80%;
    background-image: url('~@/assets/camera_frame2.png');
    /* Reference the image in the assets directory */
    background-size: contain;
    /* Adjust background size to fit the container */
    background-repeat: no-repeat;
    /* Prevent repeating the image */
    background-position: center center;
    /* Set the background color with transparency */
    background-blend-mode: multiply;
}

.kotak_bawah {
    height: 30%;
    width: 100%;
    background-color: #5c5c5c;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.containers {
    display: flex;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.logout {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 12px;
    color: blue;
}

.title {
    font-size: 1rem;
    color: white;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.sub_title {
    font-size: 0.7rem;
    color: white;
    margin-top: 5px;
}
.flash{
    position: absolute;
    top: 15px;
    right: 15px;
    color: black;
    
}
.flash_on{
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}
</style>