<template lang="">
    <div>
    <a-row class="container" type="flex" justify="center">
        <a-col :span="24" :lg="5" >
            <img src="@/assets/notfoud.png" width="200"/>
            <br>
            <div class='keterangan'> Data not found!</div>
            <br>

            <!-- <a-button @click=resetHasil class="scan_ulang"> Scan Ulang</a-button> -->

        </a-col>
    </a-row>
    <a-row  type="flex" justify="center">
        <a-col :span="24" :lg="5">
            <div class="scan_ulang" @click="resetHasil">
                <span class="scan_title">Rescan</span> 

            </div>
        </a-col>
    </a-row>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
 
export default {
    methods:{
        resetHasil(){
            let data = {
                value : "",
                isFound : false,
                alreadyCheck : false,
                isReadyToMapping: false,
                serialNumber : ""
            }
            this.updateHasilScan(data)
        },
        ...mapActions(['updateHasilScan'])
    }
}
</script>
<style scoped >
.container {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    text-align: center;
} 
.container img{
    margin-top: 40%;
}
.keterangan{
    padding-top: 30px;
    margin-bottom: 1px0px;
    font-weight: bold;
    font-size: 1rem;

}
.scan_ulangs {
  display: flex;
  justify-content: center; /* Menengahkan secara horizontal */
  align-items: center; /* Menengahkan secara vertikal */
  height: 8vh; /* Sesuaikan dengan tinggi yang sesuai */
  background-color: green;
  color: white;
  font-weight: bolder;
  position: absolute;
  bottom: 0!important;
  left: 0;
  right: 0;
}
</style>