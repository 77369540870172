<template>
    <!-- <mappingSuccessComponent /> -->
    <!-- <mappingQrComponent /> -->
    <template v-if="scan.value == ''">
        <scan-component />
    </template>
    <template v-else>
        <mappingQrComponent />
        <!-- <template v-if="scan.isFound == true">
            <mappingQrComponent />
        </template>
        <template v-else>
            <scanNotFoundComponent />
        </template> -->
    </template>

</template>
<script>
import { mapState, mapActions } from 'vuex';
import scanComponent from '@/components/scans/scanComponent.vue';
import scanNotFoundComponent from '@/components/scans/scanNotFoundComponent.vue';
import mappingQrComponent from '@/components/scans/mappingQrComponent.vue';

export default {
    components:{
        scanComponent,
        scanNotFoundComponent,
        mappingQrComponent,
    },
    data() {
     
    },
    methods:{
    },
    computed:{
        ...mapState(["scan", "detailMapping"])
    },
    mounted(){
        console.log(`halaman scan ${this.scan.value}`);
        if(this.scan.isReadyToMapping == false && this.detailMapping.serialNumber != ""){
            this.$router.push({path:"/already_mapped", replace: true})
        }
        console.log('v.1.0.3');
    },

}
</script>
<style>
  .container {
    padding: 10px;
  }  
  .camera_box{
    border-radius: 10px;
  }
</style>