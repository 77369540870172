<template lang="">
    <a-row  class="container" type='flex' justify="center">

    <a-col :span="24" :lg="5">
       
        <a-row>
            <a-col :span="24">
                <img src="@/assets/paket.png" alt="" style="width:70%" v-if="qrType == 'package'">
                <img src="@/assets/group1.png" alt="" style="width:70%" v-else>
                <a-alert :message=errorMessage v-if="errorMessage" type="error"></a-alert>

                <p class="title2">Make sure to match Serial Number on QR to {{qrType}}</p>
            </a-col>
            <a-col :span="24" class="box_informasi" v-if="qrType == 'package'">
                <span class="box_type">Type</span>
                <span class="box_type_value">Master / Package QR</span>
                <span class="box_value_sub">Master QR is Parent QR for every 1.000 QR codes</span>
            </a-col>
            <a-col :span="24" class="box_informasi" v-if="qrType == 'qrcode'">
                <span class="box_type">Type</span>
                <span class="box_type_value">QR</span>
            </a-col>
            <a-col :span="24" class="box_informasi">
                <span class="box_type">Number</span>
                <span class="box_type_value">{{scan.serialNumber}}</span>
              
            </a-col>
            
            <a-col :span="24" class="text_left">
                <div class="location_title">Province</div>
                <a-select
                    v-model:value="values.provinces"
                    mode="multiple"
                    style="width: 100%"
                    placeholder="Please select province"
                    :options="provinces"
                    :field-names="{ label: 'text', value: 'value' }"
                    @change="handleChangeProvince"
                    :status="errors.province ? 'error' : ''"
                    class="data_input"
                    :filter-option="filterOption"
                
                    
                />
                <div class="info" v-if="errors.province != ''">{{errors.province}}</div>
            </a-col>
           
            <a-col :span="24" class="text_left">
                <div class="location_title">City</div>
                <a-select
                    v-model:value="values.cities"
                    mode="multiple"
                    style="width: 100%"
                    placeholder="Please select city"
                    :options="cities"
                    :status="errors.city ? 'error' : ''"
                    :field-names="{ label: 'text', value: 'value' }"
                    @change="handleChangeCity"
                    :filter-option="filterOptionCities"


                />
                <div class="info" v-if="errors.city != ''">{{errors.city}}</div>

            </a-col>
            <div style="color:white; height:5vh;">
                .
            </div>
            <a-col :span="24">
                <a-button block :class="['save_button', {'button_block': buttonBlock}]" :disabled=buttonBlock :loading=isLoading @click=prosesData>{{(isLoading == false)?"Save Distribution Mapping":"Processing..."}}</a-button>
            </a-col>
            <a-col :span="24">
                <p @click=rescan class="rescan_button">Rescan</p>
            </a-col>
        </a-row>
        <!-- <a-button @click=resetHasil> Reset hasil</a-button> -->

    </a-col>
</a-row>

</template>
<!-- <script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
onMounted(() => {
console.log(store.state.scan);
const router = useRouter()
const scan = store.state.scan
if(scan.isReadyToMapping == false && scan.value != "")
    router.push({path:"/already_mapped"})
});
</script> -->
<script>
import { mapState, mapActions } from 'vuex';
//import axios from 'axios';
import moment from "moment";
import axios from "./../../axios/axios"
import { toHandlers } from 'vue';
export default {
    /*  setup(){
         const store = useStore();
         console.log(store.state.scan);
         onMounted(() => {
            
             });
     }, */
    data() {
        return {
            //value:"data",
            buttonBlock: false,
            errorMessage: "",
            isLoading: false,
            provinces: [],
            cities: [],
            qrType: 'qrcode',
            errors: {
                province: "",
                city: ""
            },
            values: {
                provinces: [],
                cities: []
            }
        }
    },

    methods: {
        test() {
            console.log(this.scan.value);
        },
        rescan() {
            let data = {
                value: "",
                isFound: false,
                isReadyToMapping: false,
                alreadyCheck: false,
                serialNumber: ""
            }
            this.updateHasilScan(data)
        },
        resetHasil() {
            let data = {
                value: "",
                isFound: false,
                alreadCheck: false,
                isReadyToMapping: false,
                serialNumber: ""
            }
            this.updateHasilScan(data)
        },
        filterOption(input, option) {
            return option.text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        filterOptionCities(input, option) {
            return option.text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        loadDataProvinces() {
            let url = `/api/feed/provinces?region=ID`
            axios.get(url)
                .then((response) => {
                    let data = response.data.data
                    data.map((e) => {
                        this.provinces.push({
                            value: e.code,
                            text: e.name
                        });
                    })
                    if (this.provinces.length > 1) {
                        this.provinces.unshift({
                            value: 'all',
                            text: 'All Provinces'
                        });
                    }

                })
                .catch((error) => {

                    if (error.response.status == '401' || error.response.status == '402') {
                        this.updateAuth({
                            isLogin: false,
                            token: ''
                        })
                        this.$router.push({ path: "/login" })
                    }
                })


        },
        async loadDataCities(dataProv) {
            if (dataProv != "") {
                

                    let url = `/api/feed/cities?province=${dataProv}`
                    axios.get(url)
                        .then((response) => {
                            //this.cities = [];
                            let data = response.data.data
                            data.map((e) => {
                                const foundElement = this.cities.find(element => element.value == e.code);
                                if (!foundElement) {
                                    this.cities.push({
                                        value: e.code,
                                        text: e.name
                                    });
                                }


                            })
                            const findAllcities = this.cities.find(element => element.value == 'all');
                            if (!findAllcities) {
                                if (this.cities.length > 1) {
                                    this.cities.unshift({
                                        value: 'all',
                                        text: 'All Cities'
                                    });
                                }

                            }

                            /* this.cities.some(data => {
                                console.log(data.value);
                            }) */
                            let lastCitiesValues = this.values.cities
                            
                            //let newCitiesValue = lastCitiesValues.filter(element => this.provinces.indexOf(element) !== -1);
                            let newCitiesValue = lastCitiesValues.filter(obj1 => this.cities.some(value2 => obj1 === value2.value));
                            console.log(newCitiesValue);
                            this.values.cities = newCitiesValue

                        })
                        .catch((error) => {
                            console.log(error);
                            this.errorMessage = error.message
                        });
                
            }
        },
        prosesData() {
            if (this.values.provinces.length == 0) {
                this.errors.province = "Province cannot be empty";
            } else {
                this.errors.province = "";

            }
            if (this.values.cities.length == 0) {
                this.errors.city = "City cannot be empty"
            } else {
                this.errors.city = ""

            }
            if (this.errors.province != "" || this.errors.city != "") {
                return false;
            }
            this.isLoading = true
            let self = this
            //cara ngedetectnya tinggal kalau qrcode ada HTTP://QTRU.ST, selain itu berarti package
            let myProvince = this.values.provinces.map(item => item).join(',');
            let myCities = this.values.cities.map(item => item).join(',');
            let type = 'package';
            if (this.scan.value.includes('HTTP')) {
                type = 'qrcode'
            }
            let postData =
            {
                "type": type, // qrcode | package
                "number": this.scan.value, // sample: HTTP://QTRU.ST/OpfUW | QI-541X-8ORT
                "region": "ID",
                "province": myProvince, // all | kode province with comma delimeter
                "city": myCities // all | kode city with comma delimeter
            }
            let url = `/api/mapping-distribution`
            axios.post(url, postData)
                .then((response) => {
                    let data = response.data.data;
                    let tanggal = moment(data.mapping_date).format('DD MMMM YYYY | HH:mm');
                    let dataMapping = {
                        serialNumber: (data.serial_number != "") ? data.serial_number : data.number,
                        tanggalMapping: tanggal,
                        region: data.region,
                        provinsi: data.province,
                        kota: data.city,

                    }
                    self.isLoading = false;
                    self.updateDetailMapping(dataMapping)
                    self.$router.push({ path: "/success", replace: true })
                })
                .catch((error) => {
                    this.isLoading = false

                    console.log(error);
                    if (error.response.status == 404) {
                        this.$router.push({ path: "/notfound", replace: true })
                    }
                    if (error.response.status == 401) {

                        let data = {
                            isLogin: false,
                            token: ""
                        }
                        this.updateAuth(data);

                        this.$router.push({ path: "/login", replace: true })
                    }
                    if (error.response.status == 500) {
                        this.errorMessage = error.response.data.message
                        let self = this
                        setTimeout(() => {
                            self.errorMessage = ""
                        }, 3000);
                    }
                    if (error.response.status == 501) {
                        this.$router.push({path:'/registered', replace: true})
                    }
                });
        },
        async handleChangeProvince(value) {
            this.values.provinces = value;
            const result = value.map(item => item).join(',');

            let check = this.values.provinces.indexOf('all');
            if (check == -1) {
                this.errors.province = ""
                this.buttonBlock = false
            } else {
                // ada all
                if (this.values.provinces.length > 1) {
                    // console.log('all ada yang lain');
                    this.errors.province = "Option 'All provinces' cannot be combined with other choices"
                    this.buttonBlock = true

                } else {
                    this.errors.province = ""
                    this.buttonBlock = false

                }
            }
            //this.values.cities = [];
            this.cities = [];
            this.errors.city = '';


            // console.log(newCitiesValue);
            if (result != '') {

                await this.loadDataCities(result)

            }


            //this.values.cities = ['karawang']
            //this.values.cities = newCitiesValue;
        },
        handleChangeCity(value) {
            this.values.cities = value;
            let check = this.values.cities.indexOf('all');
            if (check == -1) {
                this.errors.city = ""
                this.buttonBlock = false

            } else {
                // ada all
                if (this.values.cities.length > 1) {
                    // console.log('all ada yang lain');
                    this.errors.city = "Option 'All cities' cannot be combined with other choices"
                    this.buttonBlock = true

                } else {
                    this.errors.city = ""
                    this.buttonBlock = false

                }
            }
        },
        checkQrCode() {
            console.log(`data hasil ${this.scan.value}`);
            axios.get(`/api/check-scan?type=qrcode&number=${this.scan.value}`)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.code == 50) {
                        // already mapping
                        let scanResult = {
                            value: this.hasil,
                            isFound: true,
                            isReadyToMapping: false,
                            alreadyCheck: true,
                            serialNumber: ""
                        }
                        this.updateHasilScan(scanResult)

                        let data = response.data.data;
                        let tanggal = moment(data.mapping_date).format('DD MMMM YYYY | HH:mm');
                        let dataMapping = {
                            serialNumber: (data.serial_number != "") ? data.serial_number : data.number,
                            tanggalMapping: tanggal,
                            region: data.region,
                            provinsi: data.province,
                            kota: data.city,

                        }

                        this.updateDetailMapping(dataMapping)
                        if (this.auth.isLogin == true) {
                            this.$router.push({ path: "/already_mapped" });
                        }
                    } else {
                        // ready to mapping
                        let scanResult = {
                            value: this.hasil,
                            isFound: true,
                            isReadyToMapping: true,
                            alreadyCheck: true,
                            serialNumber: ""
                        }
                        this.updateHasilScan(scanResult)

                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status == 404) {
                        this.$router.push({ path: "/notfound", replace: true });
                    }
                    if (error.response.status == 401) {
                        let scanResult = {
                            value: this.hasil,
                            isFound: true,
                            isReadyToMapping: false,
                            alreadyCheck: false,
                            serialNumber: ""
                        }
                        this.updateHasilScan(scanResult)
                        this.$router.push({ path: "/login", replace: true });

                    }
                })
        },
        ...mapActions(['updateHasilScan', 'updateDetailMapping', 'updateAuth'])
    },
    created() {

    },
    mounted() {

        if (this.scan.value == "") {
            this.$router.push({ path: "/" })
        } else {
            if (this.scan.alreadyCheck == false) {
                console.log('qrcode belum di check');
                let self = this
                setTimeout(function () {
                    //self.checkQrCode()
                }, 300);
            } else {
                this.loadDataProvinces()
                // this.loadDataCities()
                //cara ngedetectnya tinggal kalau qrcode ada HTTP://QTRU.ST, selain itu berarti package
                // qr = HTTP://QTRU.ST
                if (this.scan.value.includes('HTTP')) {
                    this.qrType = 'qrcode'
                } else {
                    this.qrType = 'package'
                }
            }

        }

    },
    computed: {
        ...mapState(["scan", "auth"])
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

.container {
    margin: 30px;
    /* padding-top: 10vh; */
}

.title {
    font-family: "Popins", sans-serif;
    font-weight: bolder;
    font-size: 16px;
    clear: both;
}

.title2 {
    margin-top: 30px;
    font-family: "Popins", sans-serif;
    color: #B7B7B7;
    font-size: 12px;
}

.text_left {
    text-align: left;
    margin-bottom: 20px;
}

.location_title {
    margin-bottom: 5px;
    font-size: 14px;
    color: #38383880;
}

.save_button {
    margin-top: 20px;
    background-color: #387C44;
    color: white;
    border-color: #387C44;
    border-radius: 4px;
    height: 41px;
}

.save_button:active {
    color: white !important;
}

.ant-btn-default:not(:disabled):hover {
    color: white !important;
    border-color: white;
}

.space {
    color: white;
    content: '8';
}

:where(.css-dev-only-do-not-override-185kyl0).ant-select-multiple .ant-select-selection-item {
    background-color: green !important;
}

.ant-select-selection-item {
    background-color: green !important;
}

.info {
    color: red;
    font-size: 10px;
    margin-top: 5px;
}

.data_input {
    line-height: 44px;
}

.box_informasi {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin-bottom: 10px
}

.box_type {
    font-weight: thin;
    font-size: 12px;
    margin-bottom: 5px;
    color: rgba(56, 56, 56, 0.50);
}

.box_type_value {
    font-weight: bolder;
    font-size: 16px;
    margin-bottom: 5px
}

.box_value_sub {
    font-size: 10px;
    color: #B7B7B7;
}

.button_block {
    background-color: grey !important;
    border-color: grey;
}

.rescan_button {
    color: #387C44;

    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
    cursor: pointer;
}
</style>