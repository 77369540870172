<template lang="">
    <a-row type="flex" justify="center" class="container">
      <a-col :span="24" :sm="5">
        <p>testing torch</p>
        <a-button type="primary" block @click="toggleTorch"> Toggle Torch {{torch}} </a-button>
        <br />
        <br />

       <!--  <a-button block="" > Turn Off Torch</a-button> -->

    </a-col>
    </a-row>
</template>
<script>
export default {
    data() {
        return {
            torch: false
        }
    },
    methods:{
        async toggleTorch(){
            if(this.torch == true){
                this.turnOffFlashlight();
            }else{
                this.turnOnFlashlight()
            }
        },
        async turnOnFlashlight() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                const track = stream.getVideoTracks()[0];
                await track.applyConstraints({ advanced: [{ torch: true }] });
                stream.getTracks().forEach((t) => t.stop());
                this.torch = true;
            } catch (error) {
                alert('Failed to turn on the flashlight: '+error);
                /* try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { torch: true } });
                // Access granted - handle flashlight control here
                // For instance, to turn on the flashlight:
                const track = stream.getVideoTracks()[0];
                await track.applyConstraints({ advanced: [{ torch: true }] });
                } catch (error) {
                // Access denied or error occurred
                    console.error('Error - Could not access camera:', error);
                    alert('Error - Failed to turn on the flashlight :: '+error);
                } */
                console.error('Failed to turn on the flashlight:', error);
            }
        },

        // Function to turn off the flashlight
        async turnOffFlashlight() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                const track = stream.getVideoTracks()[0];
                await track.applyConstraints({ advanced: [{ torch: false }] });
                stream.getTracks().forEach((t) => t.stop());
                this.torch = false
            } catch (error) {
                alert('Failed to turn on the flashlight: '+error);

                console.error('Failed to turn off the flashlight:', error);
            }
        },
    }
}
</script>
<style scoped>
.container{
    margin:30px;
}
</style>