// axios-interceptor.js
import axios from 'axios';
import store from './../store' // Import your Vuex store module

// Create an Axios instance
const instance = axios.create({
  baseURL: `${process.env.VUE_APP_ENDPOINT}`,
});

instance.interceptors.request.use(
  (config) => {
    // Check if the request URL is not '/auth/login'
    if (config.url !== '/api/auth/login') {
      const token = store.state.auth.token;
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
