<template lang="">
    <a-row type="flex" justify="center" style="margin-left:30px;margin-right:30px;">
    <a-col class="container" :span="24" :lg="5">
        <img src="@/assets/cross.png" alt="">
        <p class="text_not_found">QR Not Found</p>
        <p class="text_detail">The QR that you scanned is not in our system</p>
        <a-row>
            <a-col :span="24">
                <a-button block class="save_button" :loading=isLoading @click=rescan>Rescan</a-button>
            </a-col>
            <a-col :span="24">
                <p class='logout_button' @click=logout v-if="auth.isLogin">Logout</p>
            </a-col>
        </a-row>
    </a-col>
</a-row>

</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
    methods:{
        rescan(){
            let data = {
                value : "",
                isFound : false,
                isReadyToMapping : false,
                alreadyCheck : false,
                serialNumber: ""
            }
            this.updateHasilScan(data)
            this.$router.push({path:"/"})
        },
        logout(){
            let data = {
                isLogin: false,
                token: ""
            }
            this.updateAuth(data);
            this.$router.push({ path: "/logout", replace: true })
        },
        ...mapActions(['updateAuth', 'updateHasilScan'])
    },
    computed:{
        ...mapState(['auth'])
    },
    created(){
        console.log('v1.0.2');
    }

}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

.container {
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 20vh;
    /* display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column; */
    align-items: center;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
}

.text_not_found {
    color: #2E2E2E;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-top: 20px;
}

.text_detail {
    color: #B7B7B7;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.save_button {
    background-color: #387C44 !important;
    color: white;
    border-color: #387C44 !important;
    border-radius: 4px;
    height: 41px;
    margin-top: 50%;
}

.logout_button {
    color: #387C44;

    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
}
</style>