<template lang="">
    <div>
        <p>Processing...</p>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    methods:{
        ...mapActions(['updateAuth', 'updateDetailMapping', 'updateHasilScan'])
    },
    created:function(){
        let data = {
            isLogin: false,
            token:""
        }
        let dataMapping = {
                serialNumber: "",
                tanggalMapping: "",
                region: "",
                provinsi: [],
                kota : []
        }
        let dataScan = {
            value : '',
            isFound : false,
            isReadyToMapping : false,
            alreadyCheck : false,
            serialNumber: ""
        }
        this.updateAuth(data)
        this.updateDetailMapping(dataMapping)
        this.updateHasilScan(dataScan)
        this.$router.push({path:"/login", replace:true})
    }
}

</script>
<style lang="">
    
</style>