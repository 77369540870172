<template lang="">
    <div>
        <a-row type="flex" justify="center">
            <a-col :span="24" :lg="5" style="text-align:center;padding-top:10vh">
                <img src="@/assets/check.svg" />
                <p class="detail_success">Already Mapped</p>

            </a-col>
        </a-row>
    </div>
    <hr style="border: 0.5px solid #E2E2E2;">
    <div style="margin: 30px;">
        <a-row type="flex" justify="center">
            <a-col :span="24" :lg="5">


                <a-row>
                    <a-col :span="24" class="list_detail">
                        <a-row>
                            <a-col :span="12" class="detail_mapping">Detail Mapping</a-col>
                            <a-col :span="12" class="value"></a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24" class="list_detail">
                        <a-row>
                            <a-col :span="12" class="title">Serial Number</a-col>
                            <a-col :span="12" class="value">{{ detailMapping.serialNumber }}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24" class="list_detail">
                        <a-row>
                            <a-col :span="12" class="title">Mapping Date</a-col>
                            <a-col :span="12" class="value">{{ detailMapping.tanggalMapping }}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24" class="list_detail">
                        <a-row>
                            <a-col :span="12" class="title">Region</a-col>
                            <a-col :span="12" class="value">{{ detailMapping.region }}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24" class="list_detail">
                        <a-row>
                            <a-col :span="12" class="title">Province</a-col>
                            <a-col :span="12" class="value">
                                {{ detailMapping.provinsi }}
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24" class="list_detail">
                        <a-row>
                            <a-col :span="12" class="title">City</a-col>
                            <a-col :span="12" class="value">
                                {{ detailMapping.kota }}

                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24">
                        <a-button block class="save_button" :loading=isLoading @click=scanUlang>Rescan</a-button>
                    </a-col>
                    <a-col :span="24">
                        <p class='logout_button' @click=logout  v-if="auth.isLogin">Logout</p>
                    </a-col>
                    <!--  <a-col :span="24" class="list_detail">
                <a-row>
                    <a-col :span="12" style="text-align: left;">
                    <a-button block @click="scanUlang"> Scan Ulang</a-button>
                    </a-col>
                   
                </a-row>
            </a-col> -->
                </a-row>
            </a-col>
        </a-row>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
export default {
    methods: {
        scanUlang() {
            let dataMapping = {
                serialNumber: "",
                tanggalMapping: "",
                region: "",
                provinsi: [],
                kota: []
            }
            let dataScan = {
                isFound: false,
                value: '',
                isReadToMapping : false,
                alreadyCheck : false,
                serialNumber: ""
            }
            this.updateDetailMapping(dataMapping)
            this.updateHasilScan(dataScan)

            this.$router.push({ path: "/", replace: true })
        },
       
        logout() {
            let data = {
                isLogin: false,
                token: ""
            }
            this.updateAuth(data);
            this.$router.push({ path: "/logout", replace: true })
        },
        ...mapActions(['updateDetailMapping', 'updateHasilScan', 'updateAuth'])
    },
    computed: {
        ...mapState(['detailMapping', 'auth'])
    },
    created(){
        let dataScan = {
                isFound: false,
                value: '',
                isReadyToMapping : false,
                alreadyCheck : false,
                serialNumber:false
            }
        this.updateHasilScan(dataScan)

    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

.container {

align-items: center;
height: 100%;
margin-left: 30px;
margin-right: 30px;
}

.title {
text-align: left;
font-size: 10px;
}

.value {
text-align: right;
font-weight: bolder;
font-size: 10px;

}

.detail_mapping {
text-align: left;
font-weight: bolder;
font-size: 14px;
}

.list_detail {
margin-top: 15px;
}

.detail_success {
font-family: "Poppins", sans-serif;
margin-top: 20px;
font-weight: 700;
}

.scan_ulang {
display: flex;
justify-content: center;
/* Menengahkan secara horizontal */
align-items: center;
/* Menengahkan secara vertikal */
height: 8vh;
/* Sesuaikan dengan tinggi yang sesuai */
background-color: green;
color: white;
font-weight: bolder;
position: absolute;
bottom: 0 !important;
left: 0;
right: 0;
}

.save_button {
background-color: #387C44 !important;
color: white;
border-color: #387C44 !important;
border-radius: 4px;
height: 41px;
margin-top: 20%;
}

.logout_button {
color: #387C44;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 30px;
}
    
</style>